import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { darken } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { motion } from 'framer-motion';
import PlanPricingFeatureItem from './PlanPricingFeatureItem';
import manageBilling from './manageBilling';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import Chip from '@mui/material/Chip';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useDispatch } from 'react-redux';


             
function PlanPricingCard({period, plan, isCurrent, hasPaidPlan, isSingle, isOnTrial, showPlans, isPublic}) {
  const { name, description, id, features, featureSummary, billingPlans, billingPrices, trialDays, popular} = plan ? plan : {};
  const dispatch = useDispatch();
  if (!plan) return null;
  const planId= id;
  const title= name;
  const subtitle= description;
  const isPopular= popular;
  const isFreePlan = plan.productId.includes("_free_plan");
  const useHighlight= isPopular || isCurrent;
  const highlightLabel= isCurrent ? "CURRENT PLAN" : "POPULAR";
  const hasTrial = trialDays && trialDays > 0;
  const yearlyPrice= billingPrices.Yearly;
  const yearlyPriceDisplay = yearlyPrice ? yearlyPrice.slice(0, yearlyPrice.length - 2) + '.' + yearlyPrice.slice(-2) : undefined;
  const yearlyPriceId= billingPlans.Yearly;
  const monthlyPrice= billingPrices.Monthly;
  const monthlyPriceDisplay = monthlyPrice ? monthlyPrice.slice(0, monthlyPrice.length - 2) + '.' + monthlyPrice.slice(-2) : undefined;
  const monthlyPriceId= billingPlans.Monthly; 
  const returnPath= window.location.hostname + "/apps/account/return";
  let buttonTitle;
  let buttonClick;
  let buttonLink;

  if (isPublic){
    if (isFreePlan){
      buttonTitle = "Create Free Account";
      buttonLink =  '/sign-up';
    }
  }else if (isSingle && isCurrent && isFreePlan){
     buttonTitle = "Upgrade Now";
     buttonClick= () => showPlans();
  }else if (isCurrent && !isFreePlan){
     buttonTitle = "Manage Billing";
     buttonClick= () => manageBilling(dispatch, returnPath);
  }else if (isFreePlan){
     buttonTitle = undefined;
     buttonClick= undefined;
  }else if (!isCurrent && hasPaidPlan){
     buttonTitle = "Change Plans";
     buttonClick= () => manageBilling(dispatch, returnPath);
  }else if (!isCurrent && !hasPaidPlan){
     if (hasTrial) buttonTitle = "Start Free Trial";
     else buttonTitle = "Get Started";
     buttonLink =  '/apps/checkout?planId=' + planId + '&priceId=' + (period === 'year' ? yearlyPriceId : monthlyPriceId);
  }
  
  console.log(features);
  console.log(plan);

  return (
    <Paper
      className={clsx(
        'flex-col max-w-sm p-24 sm:py-48 sm:px-40 relative h-full w-full',
        useHighlight && 'ring-2 ring-primary'
      )}
    >
      {useHighlight && (
        <div className="absolute inset-x-0 -top-16 flex items-center justify-center">
          <Chip
            label={highlightLabel}
            color="secondary"
            className="flex items-center h-32 px-32 rounded-full font-medium text-center leading-none"
          />
        </div>
      )}

      <Typography className="text-4xl font-bold tracking-tight leading-tight">{title}</Typography>

      <Typography className="mt-8 text-lg font-medium tracking-tight" color="text.secondary">
        {subtitle}
      </Typography>

      <Divider className="w-32 h-4 my-24 rounded bg-accent w-full" />
      
      <div className="grow"/>

      { !isFreePlan &&
        <div className="flex items-baseline whitespace-nowrap">
        <Typography className="mr-8 text-2xl">USD</Typography>
        <Typography className="text-6xl font-semibold leading-tight tracking-tight">
          {(period === 'month' || isSingle) && monthlyPriceDisplay}
          {period === 'year' && yearlyPriceDisplay}
        </Typography>
      </div>
      }
      
       {!isFreePlan &&
       <>
         <Typography className="flex flex-col" color="text.secondary">
          {(period === 'month' || isSingle) && (
              <span>per month</span>
          )}
          {period === 'year' && (
              <span>per year</span>
          )}
         </Typography>
        
         <Typography className="flex flex-col mt-4" color="text.secondary">
          {(period === 'month' || isSingle) && (
              <span>
                or <b>{yearlyPriceDisplay}</b> billed yearly
              </span>
          )}
          {period === 'year' && (
              <span>
                or <b>{monthlyPriceDisplay}</b> billed monthly
              </span>
          )}
         </Typography>
       </>
       }

        <div className="mt-40 flex flex-auto justify-center w-full">
          
            <Typography style={{ whiteSpace: "pre-wrap" }} className="text-2xl font-bold tracking-tight leading-tight">{(isCurrent && isOnTrial) ? "Trial Active" : (!isCurrent && !hasPaidPlan && hasTrial) ? "Free " + trialDays + " day trial!" : " "}</Typography>
        </div>
 
      
      {buttonTitle && 
      <Button
        className="mt-10 w-full"
        size="large"
        variant={useHighlight ? 'contained' : 'outlined'}
        color={useHighlight ? 'secondary' : 'inherit'}
        component={buttonLink ? NavLinkAdapter : undefined}
	      to={ buttonLink }
	      onClick={ buttonClick }
      >
        {buttonTitle}
      </Button>
      }
      
      <div className="flex flex-col mt-48">
          <Typography className="font-semibold">{featureSummary}</Typography>
          <div className="mt-16 space-y-8">
          
             {features.map((feature, i) => 
				      <div className="flex" key={i}>
	              <FuseSvgIcon className="text-green-600" size={20}>
	                heroicons-solid:check
	              </FuseSvgIcon>
	              <Typography className="ml-2 leading-5">
	                {feature}
	              </Typography>
	            </div>
			        )}
                       
          </div>
      </div>
      
    </Paper>
  );
}


export default PlanPricingCard;
