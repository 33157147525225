import {useLayoutEffect} from "react";
import { useLocation } from "react-router-dom";

// hook to check the app version on route change
export default function useVersionCheck() {
  // check if a version update (refresh) needed on route change
  let location = useLocation();
  useLayoutEffect(() => {
    // if there is an update available and no state passed to route
    if (!location.state && window.localStorage && window.localStorage.getItem('version-update-needed')) {
      window.localStorage.removeItem('version-update-needed'); // remove the storage object
      window.location.reload(); // refresh the browser
    }
  }, [location]);
};