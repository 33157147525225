import { stopLoading, startLoading} from 'app/store/utilSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { warningMessage } from 'app/shared-components/util/message';


async function manageBilling(dispatch, returnPath){
	 try{
		  dispatch(startLoading());
	      const apiName = 'CoreAPI2';
		    const path = '/x2/stripe/portalsession';
	      const options = {
			  body: { returnPath },
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 const data= await apiPost(apiName, path, options);
		 
		 try{
			window.open(data.url, '_blank').focus();
		 }catch(error){
			warningMessage(dispatch, "The billing window was blocked by your browser. Please check pop-up blocker settings and allow this site.");
		 }
	 }finally{
		 dispatch(stopLoading());
	 }
  }
  
export default manageBilling;