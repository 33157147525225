import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchPageBySlug, selectPageBySlug } from 'app/store/marketing/pagesSlice';
import { fetchBlogPosts, selectBlogPosts } from 'app/store/marketing/blogPostsSlice';
import { fetchTestimonials, selectTestimonials } from 'app/store/marketing/testimonialsSlice';
import Button from 'app/shared-components/marketing/Button';
import EastIcon from '@mui/icons-material/East';
import styled from '@emotion/styled';
import _ from '@lodash';
import PreviewSlider from 'app/shared-components/marketing/PreviewSlider';
import BlogPostPreview from 'app/shared-components/marketing/BlogPostPreview';
import TestimonialPreview from 'app/shared-components/marketing/TestimonialPreview';
import OrganizationHeaderTags from 'app/shared-components/helmet/OrganizationHeaderTags';


const CTAS = [
  {
    id: 0,
    title: 'Providers',
    body: 'Halespring provides a collaborative space for mental health professionals to thrive. Join our platform to expand your practice, connect with peers and clients, and enhance patient outcomes. Our HIPAA compliant platform ensures privacy and security, offering powerful features such as AI, referrals, video chat, and document sharing.',
    img: 'assets/images/marketing/home/providers.jpg',
    link: '/providers'
  },
  {
    id: 1,
    title: 'Patients',
    body: 'Halespring is your gateway to comprehensive mental health care. Discover a range of services tailored to your needs, including therapy, support groups, and educational resources. Our platform connects you with trusted professionals, ensuring a supportive and confidential environment for your journey towards well-being.',
    img: 'assets/images/marketing/home/patients.jpg',
    link: '/patients'
  }
];

const FancyUl = styled('ul')`
  li {
    white-space: nowrap;
  }

  li::before {
    content: '◉';
    margin-right: 0.8rem;
  }

  @media (min-width: 1024px) {
    li::before {
      margin-left: 0.8rem;
    }

    li:first-of-type::before {
      content: '';
    }
  }
`;

const ShapeDiv = styled('div')`
  position: relative;

  & > * {
    position: absolute;
    z-index: 0;
    transform: translateY(-6.5rem) scale(1.2);
  }

  @media (min-width: 1024px) {
    & > * {
      transform: none;
    }
  }
`;

export default function Home() {
  const routeParams = useParams();
  const dispatch = useDispatch();

  // const page = useSelector(selectPageBySlug('home'));
  const blogPosts = useSelector(selectBlogPosts());
  const testimonials = useSelector(selectTestimonials());

  useEffect(() => {
    // dispatch(fetchPageBySlug('home'))
    dispatch(fetchBlogPosts({
      pagination: {
        page: 1,
        pageSize: 3
      },
      sort: ['publishedAt:desc']
    }));
    dispatch(fetchTestimonials({
      pagination: {
        page: 1,
        pageSize: 3
      },
      sort: ['publishedAt:desc']
    }));
  }, [])
  
  return (
    <>
    <OrganizationHeaderTags name="Halespring" title="Halespring: the new mental health community" email="info@halespring.com" 
                            description="An online community for the mental health ecosystem, offering essential tools for professionals, patients, and loved ones." />
    <div>
      {/* Hero */}
      <section className="pt-40 lg:pt-24 pb-32 lg:pb-76">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-y-12">
            <div className="lg:w-1/2 lg:pr-64 flex flex-col justify-center gap-y-12 lg:gap-y-20">
              <h2 className="text-hs-grey-black text-48 lg:text-64 font-medium leading-none text-center lg:text-left">
                Empowering<br />
                Mental Health<br />
                Professionals<br />
                & Patients
              </h2>

              <div className="text-hs-grey-black text-18 lg:text-24 text-center lg:text-left">
                Connecting Providers with Clients, Improving Lives Together
              </div>

              <div className="hidden lg:block">
                <Button
                  className="bg-hs-blue text-hs-white"
                  to="/sign-up"
                >
                  Provider Sign Up
                </Button>
              </div>
            </div>

            <div className="row-span-3 grid grid-cols-2 grid-rows-2 grid-flow-row gap-14 lg:w-1/2">
              <div className="aspect-w-1 aspect-h-1">
                <div className="w-full h-full flex justify-center items-center bg-hs-blue-light text-hs-white text-16 lg:text-32 text-center rounded-full rounded-br-0">
                  HIPAA<br />
                  Compliant<br />
                  Collaboration
                </div>
              </div>

              <div className="aspect-w-1 aspect-h-1">
                <img
                  src="assets/images/marketing/home/hero-0.jpg"
                  className="w-full h-full object-cover rounded-16"
                />
              </div>

              <div className="aspect-w-1 aspect-h-1">
                <img
                  src="assets/images/marketing/home/hero-1.jpg"
                  className="w-full h-full object-cover rounded-16"
                />
              </div>

              <div className="aspect-w-1 aspect-h-1">
                <div className="w-full h-full flex justify-center items-center bg-hs-green text-hs-white text-20 lg:text-36 font-semibold text-center rounded-full rounded-tl-0">
                  Search<br />
                  and<br />
                  Referrals
                </div>
              </div>
            </div>
            
            <div className="lg:hidden mt-28">
              <Button
                className="bg-hs-blue text-hs-white hover:bg-hs-blue-dark"
                to="/sign-up"
              >
                Provider Sign Up
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* CTAs */}
      <section className="bg-hs-white py-60">
        <div className="container flex flex-col lg:flex-row gap-x-12 gap-y-16">
          {CTAS.map((cta) => 
            <article
              key={cta.id}
              className="overflow-hidden rounded-20 odd:bg-hs-blue-pale even:bg-hs-purple"
            >
              <img
                src={cta.img}
                className="w-full h-200 object-cover"
              />

              <div className="flex flex-col gap-y-20 p-40 pt-20">
                <h2 className="text-hs-blue text-48 leading-none font-medium">
                  {cta.title}
                </h2>

                <p className="text-hs-grey-black text-18 font-medium">
                  {cta.body}
                </p>

                <div>
                  <Button
                    to={cta.link}
                    className="text-hs-blue outline-hs-blue"
                  >
                    Learn More
                    <EastIcon />
                  </Button>
                </div>
              </div>
            </article>
          )}
        </div>
      </section>

      {/* Search */}
      <section className="relative">
        <div className="absolute z-0 top-0 left-0 w-full h-1/2 bg-hs-white" />

        <div className="lg:container">
          <div className="relative z-10 bg-hs-blue lg:rounded-20 text-center px-36 py-60 lg:pt-92 lg:pb-68 ">
            <h2 className="text-48 text-hs-white leading-none font-medium lg:uppercase">
              Find Mental Health Care Providers
            </h2>

            <p className="text-24 text-hs-white mt-20 lg:mt-30">
              Are you a patient or caretaker? Halespring is coming soon for you!
            </p>

            <div className="mt-20 lg:mt-60">
              <Button
                to="/patients"
                className="bg-hs-green text-hs-white"
              >
                Learn More
              </Button>
            </div>
            
{/* search module 
            <div className="mt-20 lg:mt-52">
              <input
                placeholder="Enter Zip Code"
                className="placeholder:text-hs-grey-dark text-hs-black px-20 py-10 text-24 rounded-full w-full lg:w-initial"
              />
            </div>

            <div className="mt-20 lg:mt-60">
              <Button
                to="/"
                className="bg-hs-green text-hs-white"
              >
                Next
              </Button>
            </div>
*/}
          </div>
        </div>
      </section>

      {/* Blog */}
      <section className="pt-60 lg:pt-120 pb-60">
        <div className="container">
          <PreviewSlider
            title="Blog"
            moreBtn={{
              title: 'Read All',
              to: '/blog'
            }}
          >
            {blogPosts.map((post) =>
              <BlogPostPreview
                key={post.id}
                post={post}
              />
            )}
          </PreviewSlider>
        </div>
      </section>

      {/* Provider Sign-up */}
      <section className="relative bg-hs-blue-pale flex flex-col items-center">
        <div className="container bg-hs-blue-light lg:bg-transparent lg:absolute z-10 top-0 pt-60 flex flex-col items-center w-full text-center">
          <h2 className="text-hs-grey-black text-48 leading-none font-semibold lg:uppercase">
            Join our HIPAA-Compliant<br />
            Mental Health Network
          </h2>

          <FancyUl className="mt-20 lg:mt-40 flex flex-col lg:flex-row text-hs-grey-black text-24 lg:text-28 lg:font-medium">
            <li>Artificial Intelligence</li>
            <li>Secure Referrals</li>
            <li>Searchable Profiles</li>
            <li>Connect and Share</li>
            <li>Professional Groups</li>
          </FancyUl>

          <div className="lg:hidden w-full mt-20">
            <Button
              className="bg-hs-blue text-hs-white"
              to="/sign-up"
              dense
            >
              Provider Sign Up
              <EastIcon className="ml-2" />
            </Button>
          </div>
        </div>

        <div className="aspect-w-2 aspect-h-1 w-full">
          <ShapeDiv className="hidden lg:block absolute top-0 left-0 w-full h-full z-20 pointer-events-none">
            {/* White circle outline */}
            <div
              className="border-2 border-hs-white rounded-full"
              style={{ width: '5%', height: '10%', top: '-5%', left: '10%' }}
            />
          </ShapeDiv>

          <ShapeDiv className="bg-hs-blue-light rounded-b-full pt-1/2 overflow-hidden">
            {/* iPad */}
            <img
              src="assets/images/marketing/home/ipad.png"
              className="z-10"
              style={{ width: '60%', left: '15%', bottom: '-30%' }}
            />

            {/* Green shape */}
            <div
              className="bg-hs-green"
              style={{ width: '10%', height: '20%', borderRadius: '0 50%', top: '50%', left: '20%', transform: 'rotate(-15deg)'}}
            />
            <div
              className="bg-hs-green"
              style={{ width: '5%', height: '10%', borderRadius: '50% 0', top: '50%', left: '65%', transform: 'rotate(5deg)'}}
            />

            {/* Blue shape */}
            <div
              className="bg-hs-blue opacity-40"
              style={{ width: '5%', height: '10%', borderRadius: '50% 50% 0', top: '45%', left: '30%', transform: 'rotate(15deg)'}}
            />
            <div
              className="bg-hs-blue opacity-40"
              style={{ width: '10%', height: '20%', borderRadius: '0 50% 50%', top: '70%', left: '65%', transform: 'rotate(-5deg)'}}
            />

            {/* White circle outline */}
            <div
              className="border-2 border-hs-white rounded-full"
              style={{ width: '2.5%', height: '5%', top: '65%', left: '15%' }}
            />
            <div
              className="border-2 border-hs-white rounded-full"
              style={{ width: '2.5%', height: '5%', top: '60%', left: '70%' }}
            />
            <div
              className="border-2 border-hs-white rounded-full hidden lg:block"
              style={{ width: '5%', height: '10%', top: '20%', left: '90%' }}
            />

            {/* White circle */}
            <div
              className="bg-hs-white rounded-full opacity-50"
              style={{ width: '5%', height: '10%', top: '65%', left: '75%' }}
            />

            {/* Button */}
            <Link
              className="hidden lg:flex justify-center items-center flex-nowrap bg-hs-blue text-hs-white rounded-full"
              style={{ width: '10%', height: '20%', top: '45%', left: '75%' }}
              to="/sign-up"
            >
              <svg
                viewBox="0 0 70 34"
                className="flex-none"
                preserveAspectRatio="xMinYMin meet"
                style={{ width: '50%' }}
              >
                <text fill="currentColor">
                  <tspan x="0" dy="1em">PROVIDER</tspan>
                  <tspan x="0" dy="1em">SIGN UP</tspan>
                </text>
              </svg>
              <EastIcon
                preserveAspectRatio="xMinYMin meet"
                style={{ width: '20%', height: '20%'  }}
              />
            </Link>
          </ShapeDiv>
        </div>
      </section>

      {/* Testimonials */}
      <section className="bg-hs-blue-pale py-80">
        <div className="container">
          <PreviewSlider
            title="Stories of Hope and Healing"
          >
            {testimonials.map((testimonial) =>
              <TestimonialPreview
                key={testimonial.id}
                testimonial={testimonial}
              />
            )}
          </PreviewSlider>
        </div>
      </section>
    </div>
    </>
  );
}
