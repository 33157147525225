import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import withReducer from 'app/store/withReducer';
import { useSnackbar } from 'notistack';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectUser } from 'app/store/userSlice';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NotificationCard from './NotificationCard';
import { setDMUserDialogDetails } from 'app/store/contactsSlice';
import { selectSupportIsUnread } from 'app/store/supportSlice';

import {
  dismissAll,
  dismissItem,
  getNotifications,
  selectNotifications,
  selectNotificationsUnread,
  setNotificationsRead
} from 'app/store/notificationsSlice';
import reducer from './store';
import {
  closeNotificationPanel,
  selectNotificationPanelState,
  toggleNotificationPanel,
} from './store/stateSlice';
import { subscribeToNotifications } from 'app/store/subscriptionSlice';


const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.default,
    width: 320,
  },
}));

function NotificationPanel(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);
  const notifications = useSelector(selectNotifications);
  const unread = useSelector(selectNotificationsUnread);
  const supportUnread = useSelector(selectSupportIsUnread);
  const user = useSelector(selectUser);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //Use date rounded to nearest minute to force refresh display of notification times
  const coeff = 1000 * 60 * 1;
  const date = Date.now()
  const rounded = Math.round(date / coeff) * coeff;
  const [timeKey, setTimeKey]= useState(rounded);
  
  
 /* const getNewNotifications = useCallback(async () => {
    const response = await fetchNewNotifications(user.id, lastNotification);
    let lastItem= null;
    for (let i in response) {
		const notification= response[i];
		addNewNotification(notification);
		lastItem= notification;
	}
	if (lastItem) dispatch(setLastNotification(lastItem));
  }, [lastNotification]);
  useIntervalAsync(getNewNotifications, 60000);*/

  useEffect(() => {
	dispatch(subscribeToNotifications({enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
    dispatch(getNotifications());    
  }, []);

  useEffect(() => {
    if (state) {
      dispatch(closeNotificationPanel());
    }
  }, [location, dispatch]);
  
  useEffect(() => {
    if (state && unread) {
		  dispatch(setNotificationsRead());
    }
    if (timeKey != rounded){
		setTimeKey(rounded);
		console.log("Forcing timeKey refresh.");
	}
  }, [state]);

  function handleClose() {
    dispatch(closeNotificationPanel());
  }

  function handleDismiss(id) {
    dispatch(dismissItem(id));
  }
  function handleDismissAll() {
    dispatch(dismissAll());
  }
  
  function onClickCard(item) {
     if (item.onClick === 'dmModal'){
	     dispatch(setDMUserDialogDetails({targetUserId: item.dmUserId, title: item.dmTitle, channelId: item.dmChannelId}));
	     handleClose();
	   }
  }
  

 
  return (
    <StyledSwipeableDrawer
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >
      <IconButton className="m-4 absolute top-0 right-0 z-999" onClick={handleClose} size="large">
        <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
      </IconButton>
      {notifications.length > 0 ? (
        <FuseScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="flex justify-between items-end mb-36">
              <Typography className="text-28 font-semibold leading-none">Notifications</Typography>
            </div>
            {supportUnread && 
              <NotificationCard
                key="support"
                className="mb-16"
                unreadSupport
                item={{
					title: "Unread Support Messages",
                    description: "You have unread messages from Halespring support.",
                    useRouter: true,
                    link: "/apps/help-center/support",
                    icon: "material-solid:live_help",
				}}
                onClose={handleDismiss}
              />
            }
            {notifications.map((item) => (
              <NotificationCard
                key={item.id}
                className="mb-16"
                item={item}
                onClose={handleDismiss}
                onClick={item.onClick ? onClickCard : undefined}
                lastReadTimestamp={user.raw.lastReadNotifications}
                timeKey={timeKey}
              />
            ))}
          </div>
        </FuseScrollbars>
      ) : (
        <div className="flex flex-1 items-center justify-center p-16">
          <Typography className="text-24 text-center" color="text.secondary">
            There are no notifications for now.
          </Typography>
        </div>
      )}
    </StyledSwipeableDrawer>
  );
}

export default withReducer('notificationPanel', reducer)(memo(NotificationPanel));
