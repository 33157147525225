import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderTags from 'app/shared-components/helmet/HeaderTags';
import DefaultButton from 'app/shared-components/layout/DefaultButton';
import PlanPricingCards from '../../../shared-components/account/PlanPricingCards';


const ABOUTS = [
  {
    title: 'Who we Are',
    body: 'Halespring is dedicated to transforming the mental health landscape by providing a secure, HIPAA compliant platform with essential features for mental health professionals, patients, and their loved ones.',
    icon: 'help'
  },
  {
    title: 'Our Mission',
    body: 'At Halespring, our mission is to empower providers and patients alike, facilitating better connections, more collaboration, and improved care. Our goal is to drive better patient outcomes through our powerful suite of tools.',
    icon: 'favorite_border'
  }
];

export default function Pricing() {
  const dispatch = useDispatch();
  const type= 'provider';
  const host = window.location.protocol + '//' + window.location.host;
  const orgImage=  host + '/assets/images/logo/HALESPRING-HEADER-191.jpg';
  

  return (
    <>
      <HeaderTags title="Halespring Pricing: Subscription tiers" 
                  description="Learn more about the different levels of Halespring subscriptions and their pricing." 
                  keywords="Halespring,Subscription,Pricing,Mental Health,Therapist,Therapy,Community,Referrals,Pyschologist,Psychiatrist"
                  image={orgImage}
      />
    <div>
      {/* HEADER */}
      <section>
        <div className="container pt-40 lg:pt-48 pb-44 lg:pb-52">
          <h1 className="h2 lg:h1 uppercase lg:normal-case text-center">
            Subscription Pricing
          </h1>
          <p className="body-md lg:body-lg text-center mt-12 lg:mt-20">
            Subscription Tiers, Features, and Prices
          </p>
        </div>
      </section>

      {/* Pricing */}
      <section className="bg-hs-blue-pale">
        <div className="container pt-40 pb-40">
           <PlanPricingCards type={type} isPublic />
        </div>
      </section>

    </div>
    </>
  )
}
