import Pricing from './Pricing';

const PricingConfig = {
  settings: {
    layout: {
      style: 'layoutMarketing',

      config: {
        header: {
          display: true
        },

        footer: {
          display: true
        }
      },
    },
  },

  auth: null,

  routes: [
    {
      path: '/pricing',
      element: <Pricing />,
    },
  ],
};

export default PricingConfig;
