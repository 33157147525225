import { handleRejectedAction } from "./utilSlice";

const loginStatusChecker = ({ dispatch, getState }) => next => action => {
  const userId= getState().user.id;
  console.log(action);
  if (userId == "1"){
	  if (!action || typeof action === 'function' ||
	      action.type === 'util/startLoading' ||
	      action.type === 'util/stopLoading' ||
	      action.type === 'message/showMessage' ||
	      action.type === 'message/hideMessage' ||
	      action.type === 'settings/setSettings' ||
	      action.type === 'user/userLoggedOut' ||
	      action.type === 'externalMessages/setUserToMessage' ||
	      action.type === 'searchApp/timeline/setSearchResults' ||
	      action.type === 'searchApp/timeline/clearSearch' ||
	      action.type === 'programs/resetSearchResults' ||
	      action.type.startsWith('util/handleRejectedAction') ||
	      action.type.startsWith('util/fetchCurrentLocation') ||
	      action.type.startsWith('externalMessages/createExternalMessage') ||
	      action.type.startsWith('user/setUser') ||
	      action.type.startsWith('post/fetchPublicPost') ||
	      action.type.startsWith('searchApp/') ||
	      action.type.startsWith('referrals/createReferralPublic') ||
	      action.type.startsWith('post/searchPostsPublic') ||
	      action.type.startsWith('programs/searchPrograms') ||
	      action.type.startsWith('programs/fetchProgramProfile') ||
	      action.type.startsWith('orgLocations/fetchLocationProfile') ||
	      action.type.startsWith('channels/fetchChannelPublic') || 
	      action.type.startsWith('contacts/loadProviderInfo') ||
	      action.type.startsWith('contacts/loadUserByScreenName') ||
	      action.type.startsWith('contacts/loadUserById') ||
	      action.type.startsWith('contacts/resetSearchResults') ||
		  action.type.startsWith('subscriptionPlan/getPublicPlans') ||
		  action.type.startsWith('marketing/'))
	  {
		  return next(action);
	  }
	  else{
		  dispatch(handleRejectedAction(action));
	  } 
  }
  else return next(action);
}

export default loginStatusChecker;