import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { successMessage, infoMessage } from 'app/shared-components/util/message';
import { logoutUser, setUser, signedUpUser, confirmedUser, requestedPasswordReset, forgotPasswordReset, userMfaRequired } from 'app/store/userSlice';
import jwtService from './services/jwtService';
import useInterval from 'app/shared-components/util/useInterval';
import useIdle from 'app/shared-components/util/useIdle';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();
  const refreshInterval= 3500000; //just under 1 hour
  const idleInterval= 14400000; // 4 hours
  //const idleInterval= 60000; //1 minute
  //const refreshInterval= 30000; //1/2 minute
  const [startRefresh, stopRefresh]= useInterval(refreshLogin, refreshInterval);
  const isIdle= useIdle(idleInterval);
  
  //Needed for the callback refreshLogin function or it never gets the current value
  const isAuthenticatedRef = useRef();
  isAuthenticatedRef.current = isAuthenticated;

  function refreshLogin(){
    console.log("Refresh interval: " + isAuthenticatedRef.current);
    if (isAuthenticatedRef.current) jwtService.refreshLogin();
  }
  
  useEffect(() => {
    if (isIdle && isAuthenticated) jwtService.logout();
  }, [isIdle]);
  
  useEffect(() => {
    jwtService.on('onAutoLogin', () => {
	    console.log("Inside onAutoLogin.")
	    infoMessage(dispatch, 'Refreshing sign-in.');

      /**
      * Sign in and retrieve user data with stored token
      */
      jwtService
        .signInWithToken()
        .then((user) => {
          success(user, 'Sign-in refreshed!');
        })
        .catch((error) => {
          pass(error.message);
        });
    });

    jwtService.on('onLogin', ({user: user, channelId: channelId}) => {
      success(user, null, channelId);
    });

    
    jwtService.on('onLogout', () => {
      dispatch(logoutUser());
      setIsAuthenticated(false);
    });
    
    jwtService.on('onSignup', ({userId, email, channelId, referralRequest}) => {
      pass();
	    dispatch(signedUpUser({userId, email, channelId, referralRequest}));
    });
    
    jwtService.on('onResendConfirmationCode', ({email}) => {
      pass('Please await confirmation code via email.');
	  dispatch(signedUpUser({email}));
    });
    
    jwtService.on('onConfirmSignup', ({email, channelId}) => {
      pass('Confirmed account successfully! Please sign-in');
	
	  dispatch(confirmedUser(email, channelId));
    });
    
    jwtService.on('onForgotPassword', ({email}) => {
      pass('Request submitted. Please await reset code via email.');
	  dispatch(requestedPasswordReset(email));
    });
    
    jwtService.on('onForgotPasswordReset', ({email}) => {
      pass('Password reset. Please login using new password.');
	  dispatch(forgotPasswordReset(email));
    });
    
    jwtService.on('onChangePassword', () => {
      pass('Password changed!');
    });

    jwtService.on('onAutoLogout', (message) => {
      pass(message);
      dispatch(logoutUser());
    });

    jwtService.on('onNoAccessToken', () => {
      pass();
    });

    jwtService.init(refreshInterval);

    function success(user, message, channelId) {
      if (message) {
        successMessage(dispatch, message);
      }
	
	    //User must be set before anything else
      dispatch(setUser(user)).then((resp) => {
		  setWaitAuthCheck(false);
		  setIsAuthenticated(true);  
	  });
    }

    function pass(message) {
      if (message) {
        successMessage(dispatch, message);
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
    
    startRefresh();
  }, [dispatch]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
