import { configureStore, isRejected, createListenerMiddleware} from '@reduxjs/toolkit';
import createReducer from './rootReducer';
import SubscriptionManager from './SubscriptionManager';
import crashReporter from 'app/shared-components/error/crashReporter';
import loginStatusChecker from './loginStatusChecker';
import { logoutUser } from 'app/store/userSlice';
import { signOut } from 'aws-amplify/auth';


if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}


const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  matcher: isRejected,
  effect: async (action, {state, dispatch}) => {
    console.log("Got REJECTED thunk action");
    console.log(action);
    if (action.error?.name == "SecurityError" || action.error?.message == "Unauthorized"){
       dispatch(logoutUser());
       signOut({ global: true });
    }
  },
})

const middlewares = [loginStatusChecker, crashReporter, listenerMiddleware.middleware];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });
  middlewares.push(logger);
}

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: {
        extraArgument: SubscriptionManager,
      },
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  console.log("Injecting reducer: " + key);
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

export default store;
