
import Button from 'app/shared-components/marketing/Button';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTestimonials, selectTestimonials } from 'app/store/marketing/testimonialsSlice';
import { useEffect, useRef } from 'react';
import TestimonialPreview from 'app/shared-components/marketing/TestimonialPreview';
import PreviewSlider from 'app/shared-components/marketing/PreviewSlider';
import TextBubble from 'app/shared-components/marketing/TextBubble';
import HeaderTags from 'app/shared-components/helmet/HeaderTags';
import FeatureGrid from 'app/shared-components/marketing/FeatureGrid';
import PlanPricingCards from '../../../shared-components/account/PlanPricingCards';

const features = [
  {
    id: 0,
    title: 'Searchable Profile',
    body: 'Customize your professional profile to showcase your background, areas of expertise, credentials, and more. Be easily searchable by peers and patients for efficient referrals and networking.',
    icon: 'search'
  },
  {
    id: 1,
    title: 'Referral Exchange',
    body: 'Create comprehensive referrals searchable by members, and find patients that fit your expertise. Simplify the referral process and match patients with the right provider seamlessly.',
    icon: 'swap_horiz'
  },
  {
    id: 2,
    title: 'Artificial Intelligence',
    body: 'Leverage next generation AI in a private, HIPAA compliant environment to summarize and analyze conversations, case notes, clinical studies and more - helping you learn, grow, and be more efficient!',
    icon: 'material-outline:auto_awesome'
  },
  {
    id: 3,
    title: 'Team Collaboration',
    body: 'Efficiently collaborate with other providers, share information and documents securely, and communicate in real- time. Build stronger care teams and improve patient outcomes.',
    icon: 'chat'
  },
  {
    id: 4,
    title: 'Connect and Share',
    body: 'Find other mental health professionals to connect and share with. Create, join, and network in discussion groups tailored for providers of mental health services.',
    icon: 'share'
  },
  {
    id: 5,
    title: 'HIPAA Compliant',
    body: 'Halespring was built from the ground up to be secure and HIPAA-compliant in order to keep all information private and safe. This includes direct messages to clients and other providers, as well as team collaboration channels to share case information.',
    icon: 'lock'
  },
]

export default function Providers() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTestimonials({
      pagination: {
        page: 1,
        pageSize: 3
      },
      sort: ['publishedAt:desc']
    }));
  }, [])

  const testimonials = useSelector(selectTestimonials());

  const testimonialSlider = useRef(null);
  
  const host = window.location.protocol + '//' + window.location.host;
  const orgImage=  host + '/assets/images/logo/HALESPRING-HEADER-191.jpg';

  return (
    <>
      <HeaderTags title="Halespring: tools for mental health professionals" 
                  description="An online community for the mental health ecosystem, offering essential tools for professionals, patients, and loved ones." 
                  keywords="HIPAA, AI, Mental Health,Therapist,Therapy,Community,Referrals,Pyschologist,Psychiatrist"
                  image={orgImage}
      />
    <div className="pt-48">
      {/* Hero */}
      <section>
        <div className="container">
          <h1 className="h3 lg:h1 text-center">
            Collaborate Confidently. Create Community. Improve Outcomes.
          </h1>

          <p className="body lg:body-lg text-center mt-12 lg:mt-20">
            The Halespring platform is designed for the behavioral health community. 
            Build stronger relationships, grow your business and deliver quality patient outcomes.
          </p>

          <div className="flex justify-center mt-12 lg:mt-40">
            <Button to="/sign-up" className="bg-hs-blue text-hs-white">
              Get Started Now
            </Button>
          </div>
        </div>

        <div className="relative mt-12 lg:mt-0">
          <div className="bg-hs-white absolute bottom-0 left-0 w-full h-1/2 z-0" />

          <div className="container">
            <div className="grid grid-cols-2 lg:grid-cols-4 lg:grid-rows-2 lg:grid-flow-col items-center gap-20 relative z-10">
              {/* <img className="w-full hidden lg:block lg:order-0" src="/assets/images/marketing/providers/early-access.svg" /> */}
              <TextBubble
                className="w-full hidden lg:block lg:order-0"
                shape="onePoint"
                label={['Free', 'Online Profile']}
                shapeClass="rotate-180"
              />
              {/* <img className="w-full order-1 lg:order-1" src="/assets/images/marketing/providers/request.svg" /> */}
              <TextBubble
                className="w-full order-1 lg:order-1"
                shape="twoPoints"
                label={['Artificial', 'Intelligence']}
                shapeClass="fill-hs-green"
              />
              <img className="w-full col-span-2 row-span-2 rounded-20 order-0 lg:order-2" src="/assets/images/marketing/providers/people.jpg" />
              {/* <img className="w-full hidden lg:block lg:order-3" src="/assets/images/marketing/providers/reserve.svg" /> */}
              <TextBubble
                className="w-full hidden lg:block lg:order-3"
                shape="twoPoints"
                label={['HIPAA', 'Compliant']}
                shapeClass="fill-hs-green"
              />
              {/* <img className="w-full order-2 lg:order-4" src="/assets/images/marketing/providers/pros.svg" /> */}
              <TextBubble
                className="w-full order-2 lg:order-4"
                shape="onePoint"
                label={['Professional', 'Referrals']}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Features */}
      <section className="bg-hs-white">
        <div className="container py-40">
          <h2 className="h3 lg:h2 text-center uppercase">
            Our Features
          </h2>

          <FeatureGrid features={features} />
        </div>
      </section>

      {/* Sign Up */}
      <section className="relative bg-hs-blue-pale">
        <div className="absolute z-0 top-0 left-0 w-full h-1/2 bg-hs-white" />

        <div className="lg:container">
          <div className="relative z-10 bg-hs-blue lg:rounded-20 text-center px-36 py-60 lg:pt-92 lg:pb-68 ">
            <h2 className="h2 uppercase text-hs-white text-center">
              Join Halespring Today
            </h2>

            <p className="body mt-20 lg:mt-30 text-hs-white ">
              Sign up now to join Halespring and revolutionize the way you collaborate,
              grow your business, and deliver quality patient outcomes.
            </p>

            <div className="mt-20 lg:mt-60">
              <Button
                to="/sign-up"
                className="bg-hs-green text-hs-white"
              >
                Sign Up Now
              </Button>
            </div>
          </div>
        </div>
      </section>
      
      {/* Pricing */}
      <section className="bg-hs-blue-pale pt-60">
        <div className="container">
           <h2 className="h3 lg:h2 text-center uppercase">
            Our Pricing
           </h2>
           <PlanPricingCards type='provider' isPublic />
        </div>
      </section>

      {/* Testimonials */}
      <section className="bg-hs-blue-pale py-80">
        <div className="container">
          <PreviewSlider
            title="How we help"
          >
            {testimonials.map((testimonial) =>
              <TestimonialPreview
                key={testimonial.id}
                testimonial={testimonial}
              />
            )}
          </PreviewSlider>
        </div>
      </section>
    </div>
    </>
  );
}