import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { darken } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import PlanPricingCard from './PlanPricingCard';
import { selectPublicPlans, getPublicPlans, selectPlans, getPlans } from 'app/store/subscriptionPlanSlice';
import { useDispatch, useSelector } from 'react-redux';

function PlanPricingCards({type, onFreePlan, isOnTrial, activePlan, isPublic}) {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState('month');
  const subscriptionPlans= useSelector(selectPublicPlans);
  //const subscriptionPlans= useSelector(selectPlans);
  const sortedPlans= subscriptionPlans.slice();
  const currentPlanId= activePlan?.id;
  console.log("Current plan: " + currentPlanId);
  
  
  sortedPlans.sort((a,b) => {
	  const monthlyPriceA= a.billingPrices.Monthly;
    const monthlyPriceB= b.billingPrices.Monthly;
    
    if (!monthlyPriceA) return -1; //a is free, goes first
    else if (!monthlyPriceB) return 1; //b is free, goes first
    else return monthlyPriceA.localeCompare(monthlyPriceB);
  })

  useEffect(() => {
    dispatch(getPublicPlans({type: type}));
    //dispatch(getPlans({type: type}));
  }, [type]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 100 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div className="relative flex flex-col flex-auto min-w-0 overflow-hidden">
      <div className="relative px-24 overflow-hidden">

        <div className="flex flex-col items-center">

          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
            <Box
              className="flex items-center p-2 rounded-full overflow-hidden"
              sx={{ backgroundColor: (theme) => darken(theme.palette.background.default, 0.05) }}
            >
              <Box
                component="button"
                className={clsx(
                  'h-40 items-center px-16 cursor-pointer rounded-full font-medium',
                  period === 'month' && 'shadow'
                )}
                onClick={() => setPeriod('month')}
                sx={{ backgroundColor: period === 'month' ? 'background.paper' : '' }}
                type="button"
              >
                Monthly billing
              </Box>
              
              <Box
                component="button"
                className={clsx(
                  'h-40 items-center px-16 cursor-pointer rounded-full font-medium',
                  period === 'year' && 'shadow'
                )}
                onClick={() => setPeriod('year')}
                sx={{ backgroundColor: period === 'year' ? 'background.paper' : '' }}
                type="button"
              >
                Yearly billing
              </Box>

            </Box>
          </motion.div>
        </div>

        <div className="flex justify-center mt-40 sm:mt-80">
          <div className="w-full">
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-y-6 lg:gap-y-0 md:gap-x-24 justify-items-center items-stretch content-stretch"
            >
            
               {sortedPlans.map((plan) =>
    				      <motion.div className="mb-24" variants={item} key={plan.id}>
    	                <PlanPricingCard
    	                  period={period}
    	                  plan={plan}
    	                  isCurrent={plan.id == currentPlanId}
    	                  hasPaidPlan={!onFreePlan}
    	                  isOnTrial={isOnTrial}
                        isPublic={isPublic}
    	                />
    	            </motion.div>
      			   )}
              
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanPricingCards;
