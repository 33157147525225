import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { addChannels, fetchChannels, addChannel,
         leaveChannel, joinChannel, removeChannel, 
         setChannelRead, setChannelUnread, fetchChannel } from 'app/store/channelsSlice';
import { createChannelEntity, deleteChannelEntity, updateChannelEntity } from './groupsSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import {  startLoading, stopLoading} from 'app/store/utilSlice';

import { subscribeToChannel } from './subscriptionSlice';


export const getAssignedCases = createAsyncThunk(
  'support/getAssignedCases',
  async ({bulk, closeSnackbar, enqueueSnackbar}, { dispatch, getState }) => {
	try{
      dispatch(startLoading());
	  const { user } = getState();
	  let channels= bulk; //pre-fetched via bulk api
	  if (!channels){
		  const apiName = 'CoreAPI';
		  const path = '/user/' + user.id + '/channelmember/channels?role=STARTS:support';
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  channels= await apiGet(apiName, path, options);
	  }
      
	 channels= channels.map((channel) => { return {...channel, reverseSort: true}});
	 dispatch(addChannels(channels));
	 for (let i in channels) {
		const channel= channels[i];
		dispatch(subscribeToChannel({channelId: channel.id, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
	 }
	 
     return channels;
   }finally{
	   dispatch(stopLoading());
   }
  }
)

export const getOpenCases = createAsyncThunk(
  'support/getOpenCases',
  async ({bulk}, { dispatch, getState }) => {
	try{
      dispatch(startLoading());
	  let cases= bulk; //pre-fetched via bulk api
	  if (!cases){
		  const apiName = 'CoreAPI';
		  const path = '/channelentity/supportbystatus/open';
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  cases= await apiGet(apiName, path, options);
	  }
     
     const channelIds= cases.map((c) => c.channelId);
	 const resp= await dispatch(fetchChannels({idList: channelIds, reverseSort: true}));
	 if (resp.error) throw new Error(resp.error.message);
	 else {
		 const channels= resp.payload;
	     return channels;
	 }
   }finally{
	   dispatch(stopLoading());
   }
  }
)


export const getCase = createAsyncThunk(
  'support/getCase',
  async (params, { dispatch, getState }) => {
	try{
	  const { channelId, refresh, closeSnackbar, enqueueSnackbar } = params;
      dispatch(startLoading());
      const apiName = 'CoreAPI';
	  const path = '/channel/' + channelId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 const channel= await apiGet(apiName, path, options);
	 dispatch(addChannel({channel: {...channel, reverseSort: true}, refresh: refresh}));
	 	 
     return channel;
   }finally{
	   dispatch(stopLoading());
   }
  }
)


export const createCase = createAsyncThunk(
  'support/createCase',
  async (params, { dispatch, getState }) => {
   const { caseType, name, description, closeSnackbar, enqueueSnackbar } = params;
	 const entity= { caseType, name, description, type: 'support'};
     const resp= await dispatch(createChannelEntity({entity: entity, reverseSort: true}));
     if (resp.error) throw new Error(resp.error.message);
	 else {
		 const channel= resp.payload;
     dispatch(subscribeToChannel({channelId: channel.id, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
	   return channel;
	 }
  }
);


export const deleteCase = createAsyncThunk(
  'support/deleteCase',
  async ({id}, { dispatch, getState }) => {
	  const resp= await dispatch(deleteChannelEntity(id));
	  if (resp.error) throw new Error(resp.error.message);
	  else return id;
  }
);
 
export const updateCase = createAsyncThunk(
  'support/updateCase',
  async (params, { dispatch, getState }) => {
	  const entity= {...params, type: 'support'};
	  const resp= await dispatch(updateChannelEntity({entity: entity, reverseSort: true}));
	  if (resp.error) throw new Error(resp.error.message);
	  else return resp.payload;
  }
);

 
export const leaveCase = createAsyncThunk(
  'support/leaveCase',
  async (params, { dispatch, getState }) => {
	  await dispatch(leaveChannel(params.id));
	  dispatch(fetchChannel({channelId: params.id})); //Refresh channel 
      return params.id;
  }
);

export const joinCase = createAsyncThunk(
  'support/joinCase',
  async (params, { dispatch, getState }) => {
	  const {id, enqueueSnackbar, closeSnackbar}= params;
	  const useRole= 'member';
	  let resp= await dispatch(joinChannel({channelId: id, role: useRole}));
	  if (resp.error) throw new Error(resp.error.message);	  
	  resp= await dispatch(getCase({channelId: id, refresh: true, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
      if (resp.error) throw new Error(resp.error.message);	  
      return id;
  }
);


const caseAdapter = createEntityAdapter({});

export const newCase= (data) => {
	return { id: data.id, unread: data.unread, type: data.entity.caseType}; //has full client info
}



const supportSlice = createSlice({
  name: 'support',
  initialState: {
	  assigned: caseAdapter.getInitialState(),
	  open: caseAdapter.getInitialState(),
	  selectedTab: 0,
	  selectedId: null,
	  dialogOpen: false,
	  unread: false, 
  },
  reducers: {
    setSelectedCaseId: {
      reducer: (state, action) => {
        state.selectedId = action.payload;
      }
    },
    setSelectedSupportTab: {
      reducer: (state, action) => {
        state.selectedTab = action.payload;
      }
    },
    setSupportDialogOpen: {
      reducer: (state, action) => {
        state.dialogOpen = action.payload;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(updateCase.fulfilled, (state, action) => {
	  //handled via dispatch
    })
    .addCase(setChannelRead.fulfilled, (state, action) => {
      const channelId = action.payload;
      const channelEntry = state.assigned.entities[channelId];
      if (channelEntry) {
		 if (!channelEntry.unread) return; //No need to do anything if channel is already read
         channelEntry.unread= false;
         state.unread= isAnyUnread(state);
      }
    })
    .addCase(setChannelUnread, (state, action) => {
      const channelId = action.payload;
      const channelEntry = state.assigned.entities[channelId];
      if (channelEntry) {
		 if (channelEntry.unread) return; //No need to do anything if channel is already read
         channelEntry.unread= true;
         state.unread= true;
      }
    })
    .addCase(createCase.fulfilled, (state, action) => {
      caseAdapter.addOne(state.assigned, newCase(action.payload));
    })
 
    .addCase(deleteCase.fulfilled, (state, action) => {
      caseAdapter.removeOne(state.assigned, action.payload);
      caseAdapter.removeOne(state.open, action.payload);
      state.selectedId= null;
      state.unread= isAnyUnread(state);
    })
    .addCase(leaveCase.fulfilled, (state, action) => {
      caseAdapter.removeOne(state.assigned, action.payload);
      state.selectedId= null;
      state.unread= isAnyUnread(state);
    })
    .addCase(getAssignedCases.fulfilled, (state, action) => {
	  const caseEntries = action.payload.map(group => {
        return newCase(group);
      });
      caseAdapter.setAll(state.assigned, caseEntries);
      state.unread= isAnyUnread(state);
    })
    .addCase(getOpenCases.fulfilled, (state, action) => {
	  const caseEntries = action.payload.map(group => {
        return newCase(group);
      });
      caseAdapter.setAll(state.open, caseEntries);
    })
    .addCase(getCase.fulfilled, (state, action) => {
	  if (action.payload){
		 const supportCase= newCase(action.payload);
		 caseAdapter.setOne(state.assigned, supportCase);
		 if (supportCase.unread) state.unread= true;
	  }
    })
  },
});

const isAnyUnread= (state) => {
	for(let i=0; i < state.assigned.ids.length; i++){
		const id= state.assigned.ids[i];
		const data= state.assigned.entities[id];
		if (data && data.unread) return true;
	}
	return false;
}

const { selectAll, selectById } = caseAdapter.getSelectors();


//Get the objects for the specified type
export const selectOpenCases= (state) => {
	return selectAll(state.support.open);
} 
export const selectAssignedCases= (state) => {
	return selectAll(state.support.assigned);
} 


//Get the objects for the specified type
export const selectSupportIsUnread= (state) => {
	return state.support.unread;
} 

export const selectAssignedCaseById= (id) => (state) => {
	return selectById(state.support.assigned, id);
} 

export const selectOpenCaseById= (id) => (state) => {
	return selectById(state.support.open, id);
} 


export const selectSelectedCaseId = ({ support }) => support.selectedId;
export const selectSelectedSupportTab = ({ support }) => support.selectedTab;
export const selectSupportDialogOpen= ({ support }) => support.dialogOpen;
export const { setSelectedCaseId, setSelectedSupportTab, setSupportDialogOpen} = supportSlice.actions;

export default supportSlice.reducer;
